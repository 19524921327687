import PzSliderSimulator from "@/components/Flujo/Simuladores/SliderSimulator";
import PzVehiculoSimulator from "@/components/Flujo/Simuladores/VehiculoSimulator";
import PzVehiculoRotativaSimulator from "@/components/Flujo/Simuladores/VehiculoRotativaSimulator";
import PzDataInputSimulator from "@/components/Flujo/Simuladores/DataInputSimulator";
import PzCompanyForm1Uy from "@/components/Flujo/Formularios/Formularios1/UY/Form1_Empresa";
import PzPersonForm1Uy from "@/components/Flujo/Formularios/Formularios1/UY/Form1_Persona";
import PzPersonForm1Default from "@/components/Flujo/Formularios/Formularios1/UY/Form1_Persona_default";
import PzPersonFormEs1 from "@/components/Flujo/Formularios/Formularios1/ES/Persona/Form1_Persona1";
import PzPersonFormEs2 from "@/components/Flujo/Formularios/Formularios1/ES/Persona/Form1_Persona2";
import PzPersonFormEs3 from "@/components/Flujo/Formularios/Formularios1/ES/Persona/Form1_Persona3";
import PzPersonForm1Mx from "@/components/Flujo/Formularios/Formularios1/MX/Form1_Persona";
import PzCompanyForm1Mx from "@/components/Flujo/Formularios/Formularios1/MX/Form1_Empresa";
import PzCompanyForm2Uy from "@/components/Flujo/Formularios/Formularios2/UY/Form2_Empresa";
import PzPersonFormExtUy from "@/components/Flujo/Formularios/Formularios2/UY/Form2_Persona";
import PzPersonFormExtEs from "@/components/Flujo/Formularios/Formularios2/ES/Form2_Persona";
import PzCompanyFormExtEs from "@/components/Flujo/Formularios/Formularios2/ES/Form2_Empresa";
import PzPersonForm2Mx from "@/components/Flujo/Formularios/Formularios2/MX/Form2_Persona";
import PzCompanyForm2Mx from "@/components/Flujo/Formularios/Formularios2/MX/Form2_Empresa";
import PzGaranteFormUy from "@/components/Flujo/Formularios/Garante/UY/Form_Garante";
import PzGaranteFormMx from "@/components/Flujo/Formularios/Garante/MX/Form_Garante";
import PzCompanyDocumentacion from "@/components/Flujo/Formularios/Documentacion/FormDocumentacion_Empresa";
import PzPersonDocumentacion from "@/components/Flujo/Formularios/Documentacion/FormDocumentacion_Persona";
import PzOffersUy from "@/components/Flujo/Ofertas/UY/Ofertas";
import PzChequeDescuentoTerceros from "@/components/Flujo/Formularios/DescuentoTerceros/DescuentoTerceros_Cheque";
import PzFacturaDescuentoTerceros from "@/components/Flujo/Formularios/DescuentoTerceros/DescuentoTerceros_Factura";
import PzValidateBySms from "@/components/Flujo/Formularios/validacionSMS/ValidacionSMS";
import PzProductsAllSelect from "@/components/Flujo/ProductList/SelectProductsAll";
import PzOfertaDescuentosDefault from "@/components/Flujo/Simuladores/Ofertas/descuento/OfertaDescuentosDefault";
import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudDefault";
import PzOfertaSolicitudBase from "@/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudBase";
import PzOfertaSolicitudTable from "@/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudTable";
import PzOfertaSolicitudTableExtra from "@/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudTableExtra";
import PzOfertaUpDescuentosUy from "@/components/Flujo/Ofertas/defaults/partsDescuentos/OfertaUpDescuentosDefault";
import PzOfertaUpSolicitudUy from "@/components/Flujo/Ofertas/defaults/partsSolicitud/OfertaUpSolicitudDefault";
import PzOfertaDownSolicitudUy from "@/components/Flujo/Ofertas/defaults/partsSolicitud/OfertaDownSolicitudDefault";
import PzMessageUy from "@/components/Flujo/Final/Message";
import PzMessageEs from "@/components/Flujo/Final/MessageEs";
import PzVerificacionCliente from "@/components/Flujo/Formularios/VerificacionCliente/VerificacionCliente";
/* Vistas BNF */
import PzPersonForm1Bnf from "@/components/Flujo/Formularios/Formularios1/BNF/Form1_Persona";
import PzPersonForm1Vehiculo from "@/components/Flujo/Formularios/Formularios1/BNF/Form1_Persona_vehiculo";
import PzPersonFormExtBnf from "@/components/Flujo/Formularios/Formularios2/BNF/Form2_Persona";
import PzPersonFormExtVehiculo from "@/components/Flujo/Formularios/Formularios2/BNF/Form2_Persona_vehiculo";
import PzPersonFormExt2Bnf from "@/components/Flujo/Formularios/Formularios2/BNF/Form2_Persona2";
import PzPersonFormExtNetPC from "@/components/Flujo/Formularios/Formularios2/NETPC/Form2_Persona";
/* Vistas Prestama */
import PzPersonForm1Prestama from "@/components/Flujo/Formularios/Formularios1/PRESTAMA/Form1_Persona";
import PzPersonFormExtPrestama from "@/components/Flujo/Formularios/Formularios2/PRESTAMA/Form2_Persona";
import PzPersonFormExt2Prestama from "@/components/Flujo/Formularios/Formularios2/PRESTAMA/Form2_Persona2";
/* Vistas Fenoreste */
import PzPersonForm1Fenoreste from "@/components/Flujo/Formularios/Formularios1/FENORESTE/Form1_Persona";
import PzPersonForm2Fenoreste from "@/components/Flujo/Formularios/Formularios1/FENORESTE/Form2_Persona";
import PzPersonFormExtFenoreste from "@/components/Flujo/Formularios/Formularios2/FENORESTE/Form2_Persona";
import PzFirmaDocs from "@/components/Flujo/Formularios/FirmaDocumentos/FirmaDoc";
/* Garante con seleccion de jubilado */
import PzGaranteFormJubUy from "@/components/Flujo/Formularios/Garante/UY/Form_Garante_Jub";

/*IMPACTA*/
import PzPersonForm1Impacta from "@/components/Flujo/Formularios/Formularios1/IMPACTA/Form1_Persona";
import PzPersonForm1Impacta2 from "@/components/Flujo/Formularios/Formularios1/IMPACTA/Form1_Persona2";
import PzMessageImpacta from "@/components/Flujo/Final/MessageImpacta";


import PzPersonFormExtImpacta from "@/components/Flujo/Formularios/Formularios2/IMPACTA/Form2_Persona";
import PzPersonFormExtImpacta2 from "@/components/Flujo/Formularios/Formularios2/IMPACTA/Form2_Persona2";
import PzMessageVehiculo from "@/components/Flujo/Final/MessageVehiculo";
/*Hipotecalo*/
import PzPersonForm1Hipotecalo from "@/components/Flujo/Formularios/Formularios1/HIPOTECALO/Form1_Persona";
import PzPersonForm1Inversor from "@/components/Flujo/Formularios/Formularios1/HIPOTECALO/Form1_PersonaInversor";
import PzPersonForm2Hipotecalo from "@/components/Flujo/Formularios/Formularios1/HIPOTECALO/Form2_Persona";
import PzPersonForm3Hipotecalo from "@/components/Flujo/Formularios/Formularios1/HIPOTECALO/Form3_Persona";
import PzPersonFormExtHipotecalo from "@/components/Flujo/Formularios/Formularios2/HIPOTECALO/Form1_Persona";
import PzPersonFormExt2Hipotecalo from "@/components/Flujo/Formularios/Formularios2/HIPOTECALO/Form2_Persona";

/*CSN NEW*/
import PzPersonForm1FenoresteNew from "@/components/Flujo/Formularios/Formularios1/FENORESTE/CSN/Form1";
import PzPersonForm2FenoresteNew from "@/components/Flujo/Formularios/Formularios1/FENORESTE/CSN/Form2";
import PzPersonForm3FenoresteNew from "@/components/Flujo/Formularios/Formularios1/FENORESTE/CSN/Form3";
import PzVerificacionHashCliente from "@/components/Flujo/Formularios/VerificacionCliente/VerificacionHashCliente";
export default [
  PzOfertaUpDescuentosUy,
  PzOfertaUpSolicitudUy,
  PzOfertaDownSolicitudUy,
  PzOfertaDescuentosDefault,
  PzOfertaSolicitudDefault,
  PzOfertaSolicitudTable,
  PzProductsAllSelect,
  PzPersonFormExtEs,
  PzCompanyFormExtEs,
  PzPersonFormEs1,
  PzPersonFormEs2,
  PzPersonFormEs3,
  PzSliderSimulator,
  PzDataInputSimulator,
  PzCompanyForm1Uy,
  PzPersonForm1Default,
  PzPersonForm1Uy,
  PzOffersUy,
  PzCompanyForm2Uy,
  PzPersonFormExtUy,
  PzPersonForm2Mx,
  PzCompanyForm2Mx,
  PzCompanyDocumentacion,
  PzPersonDocumentacion,
  PzChequeDescuentoTerceros,
  PzFacturaDescuentoTerceros,
  PzValidateBySms,
  PzPersonForm1Mx,
  PzCompanyForm1Mx,
  PzGaranteFormUy,
  PzGaranteFormMx,
  PzMessageUy,
  PzVerificacionCliente,
  PzPersonForm1Bnf,
  PzPersonFormExtBnf,
  PzPersonFormExt2Bnf,
  PzPersonForm1Prestama,
  PzPersonFormExtPrestama,
  PzPersonFormExt2Prestama,
  PzFirmaDocs,
  PzPersonForm1Fenoreste,
  PzPersonFormExtFenoreste,
  PzPersonFormExtNetPC,
  PzPersonForm2Fenoreste,
  PzGaranteFormJubUy,
  PzPersonForm1Impacta,
  PzPersonForm1Impacta2,
  PzPersonFormExtImpacta,
  PzVehiculoSimulator,
  PzVehiculoRotativaSimulator,
  PzOfertaSolicitudBase,
  PzPersonForm1Vehiculo,
  PzPersonFormExtVehiculo,
  PzMessageVehiculo,
  PzOfertaSolicitudTableExtra,
  PzMessageEs,
  PzPersonFormExtImpacta2,
  PzMessageImpacta,
  PzPersonFormExtImpacta2,
  PzPersonForm1Hipotecalo,
  PzPersonForm2Hipotecalo,
  PzPersonForm3Hipotecalo,
  PzPersonFormExtHipotecalo,
  PzPersonFormExt2Hipotecalo,
  PzPersonForm1Inversor,
  PzMessageImpacta,
  PzPersonForm1FenoresteNew,
  PzPersonForm2FenoresteNew,
  PzPersonForm3FenoresteNew,
  PzVerificacionHashCliente
];
